import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import BackupIndex from '../Pages/Settings/Backup'

const Settings =lazy(()=>import('../Pages/Settings/index')) 
const CompanyInfo =lazy(()=>import('../Pages/Settings/CompanyInfo/companyInfo')) 
const CompanyForm =lazy(()=>import('../Pages/Settings/CompanyInfo/form')) 
const TaskSetting =lazy(()=>import('../Pages/Settings/Tasks')) 
const ProjectSetting =lazy(()=>import('../Pages/Settings/Projects')) 
const AllUsers =lazy(()=>import('../Pages/Settings/AllUsers')) 
const RoleMain =lazy(()=>import('../Pages/Settings/Role/roleMain')) 
const RoleSetting =lazy(()=>import('../Pages/Settings/Role')) 
const CreateRole =lazy(()=>import('../Pages/Settings/Role/createRole')) 
const EditRoleTwo =lazy(()=>import('../Pages/Settings/Role/editRoleTwo')) 
const RoleView =lazy(()=>import('../Pages/Settings/Role/roleView')) 
const NotesSetting =lazy(()=>import('../Pages/Settings/Notes')) 
const ClientSetting =lazy(()=>import('../Pages/Settings/Clients')) 
const NotificationSettings =lazy(()=>import('../Pages/Settings/Notification'))

function SettingsRoutes() {
  return (
    <Route path="settings" element={<Settings />}>
    <Route index element={<CompanyInfo />} />
    <Route path="edit" element={<CompanyForm />} />
    <Route path="task-settings" element={<TaskSetting />} />
    <Route path="project-settings" element={<ProjectSetting />} />
    <Route path="all-users" element={<AllUsers />} />
    <Route path="roles" element={<RoleMain />}>
      <Route index element={<RoleSetting />} />
      <Route path="create" element={<CreateRole />} />
      <Route path="edit-role/:roleId" element={<EditRoleTwo />} />
      <Route path="role/view/:roleViewId" element={<RoleView />} />
    </Route>
    <Route path="notes-settings" element={<NotesSetting />} />
    <Route path="client-settings" element={<ClientSetting />} />
    <Route path="notification" element={<NotificationSettings />} />
    <Route path="backup" element={<BackupIndex />} />
  </Route>
  )
}

export default SettingsRoutes