import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Utils/Breadcrumbs";
import { Link, useLocation } from "react-router-dom";
import Dasboard from "../../Components/Icons/Dasboard";
import ViewSwitcher from "./ViewSwitcher";
import { Gantt, ViewMode } from "gantt-task-react";
import { getStartEndDateForProject, initTasks } from "./helper";
import { useNumber } from "../../Context/ProjectIdContext";
import axiosInstance from "../../Services/axiosCommon";
import { Select } from "antd";
import NuDate from "../../Components/Utils/NuDate";

function TaskSchedule() {
  const { number, updateNumber } = useNumber();
  const location = useLocation();
  const projectTask = location?.state?.projectTask || "";
  const [view, setView] = useState(ViewMode.Day);
  const [tasks, setTasks] = useState(initTasks());
  const [isChecked, setIsChecked] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  const [hasEdit,setHasEdit] = useState(false);
  
  console.log("ddzzz",initTasks());


  let columnWidth;
  switch (view) {
    // case ViewMode.Hour:
    //   columnWidth = 35;
    //   break;
    // case ViewMode.QuarterDay:
    //   columnWidth = 60;
    //   break;
    // case ViewMode.HalfDay:
    //   columnWidth = 70;
    //   break;
    case ViewMode.Day:
      columnWidth = 65;
      break;
    case ViewMode.Week:
      columnWidth = 250;
      break;
    case ViewMode.Month:
      columnWidth = 300;
      break;
    case ViewMode.Year:
      columnWidth = 400;
      break;
    default:
      columnWidth = 65;
  }

  const handleTaskChange = (task) => {
    console.log("On date change Id:" , task);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
    if(task.taskType=="task"){
      UpdateTaskDate(task?.taskId,task)
    }else if(task.taskType=="sub-task") {
      UpdateSubTaskDate(task?.taskId,task)
    }
  };



  function UpdateTaskDate(taskId,value){
    let start = NuDate({value:value.start,format:"YYYY-MM-DD"})
    let end = NuDate({value:value.end,format:"YYYY-MM-DD"})

    console.log("fun time",start,end);
    let dateValue = {
      startDate:start,
      endDate:end,
    }
    axiosInstance.put(`/Task/update_date/${taskId}`,dateValue).then().catch()
  }

  function UpdateSubTaskDate(subTaskId,value){
    let start = NuDate({value:value.start,format:"YYYY-MM-DD"})
    let end = NuDate({value:value.end,format:"YYYY-MM-DD"})
    console.log("fun time",start,end);
    let dateValue = {
      startDate:start,
      endDate:end,
    }
    axiosInstance.put(`/SubTask/update_date/${subTaskId}`,dateValue).then().catch()
  }


  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    // let Value = tasks.find(x=>x.id === task.id)
    console.log("On progress change Id:" ,task);
    if(task.taskType=="task"){
      UpdateTaskPercentage( task?.progress,task?.taskId)
    }else if(task.taskType=="sub-task") {
      UpdateSubTaskPercentage(task?.progress,task?.taskId)
    }
  };

  function UpdateTaskPercentage(value,taskId){
    console.log(value);
    let completion = {
      taskCompletion:value
  }
    axiosInstance.put(`/Task/${taskId}/task-completions`,completion).then().catch()
  }

  function UpdateSubTaskPercentage(value,subTaskId){
    let completion = {
      subTaskCompletions:value
  }
    axiosInstance.put(`/SubTask/${subTaskId}/subtask-completions`, completion).then().catch()
  }



  async function getProject() {
    await axiosInstance
      .get(`/Team/getUserProjectId`)
      .then(async (res) => {
        if(number!=0){
          console.log("Dssss",number);
          setFirstProject(number)
        }else if (projectTask) {
          console.log("DD",projectTask);
          setFirstProject(projectTask);
          updateNumber(projectTask)
        } else {
          res?.data?.data[0] && setFirstProject(res?.data?.data[0]?.ProjectId)
          updateNumber(res?.data?.data[0]?.ProjectId)
          console.log("dd",res?.data?.data[0]?.ProjectId);

        }
        let list = res?.data?.data || [];
        let finalData = [];
        await res?.data?.data && list.map((item, idx) => {
          finalData.push({
            value: item.ProjectId,
            label: item.ProjectName,
          });

        })
        setProjectList(finalData);

        // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
        // console.log('====',res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  function handleProjectChange(e) {
    setFirstProject(e)
    updateNumber(e)
  }


  function getTitle(x) {
    axiosInstance.get(`/mytask/byUser/all_tasks/${x}`).then(res => {
      let listOfTitle = res.data?.data ||[]
      let num = 1;
      let taskList=[];
      if(listOfTitle.length > 0) {
        listOfTitle && listOfTitle.map(taskItem =>{
          let arr = taskItem?.taskDetails||[]
          arr && arr.map((item)=>{
            let taskName = `${item?.taskName}-${item?.taskId}`;
            let StartDate = new Date(`${item?.startDate} 12:00:00`)
            let EndDate = new Date(`${item?.endDate} 23:00:00`)

           if(item?.subTaskDetails.length>0){
            let tasks = {
              start: new Date(StartDate),
              end: new Date(EndDate),
              name: item?.taskName,
              id: `${item?.taskName}-${item?.taskId}`,
              progress: item?.taskCompletion,
              type: 'project',
              hideChildren: false,
              displayOrder: num++,
              taskId:item?.taskId,
              taskType:"task"
          }
          taskList.push(tasks);
          let subtaskArr =item.subTaskDetails;
          item?.subTaskDetails && subtaskArr.map((subitem)=>{
              let Startdate = new Date(`${subitem?.startDate} 12:00:00`)
              let Enddate = new Date(`${subitem?.endDate} 23:00:00`)

              let subtasks = {
                  start: new Date(Startdate) ,
                  end: new Date(Enddate),
                  name: subitem?.subTaskName,
                  id: `${subitem?.subTaskName}-${subitem?.subTaskId}`,
                  progress: subitem?.subTaskCompletions,
                  type: 'task',
                  project:taskName,
                  displayOrder: num++,
                  taskId:subitem?.subTaskId,
                  taskType:"sub-task"
              }
              taskList.push(subtasks);
          })
           }else{
            let tasksNoSub = {
              start: new Date(StartDate),
              end: new Date(EndDate),
              name: item?.taskName,
              id: `${item?.taskName}-${item?.taskId}`,
              progress: item?.taskCompletion,
              type: 'task',
              hideChildren: false,
              displayOrder: num++,
              taskId:item?.taskId,
              taskType:"task"
          }
          taskList.push(tasksNoSub);
           }
        })
        })
        console.log("FInal List",taskList);
        setTasks(taskList)
      }

    })
      .catch(error => { console.log(error); })
  }


  useEffect(() => {
    if(number !=0){
    getTitle(number)
    }else{
      getProject(firstProject)
    }
  }, [firstProject])

  useEffect(() => {
    // console.log("jj",projectNum);
    if (projectTask) {
      setFirstProject(projectTask)
    }
  }, [projectTask])

  return (
    <div className="w-full h-[calc(100vh-59px)] px-4 py-2 overflow-hidden">
      <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className="text-[12px]">Tasks Schedule</p> },
          ]}
        />

        <ViewSwitcher
          onViewModeChange={(viewMode) => setView(viewMode)}
          onViewListChange={setIsChecked}
          isChecked={isChecked}
          viewMode={view}
          hasEdit={hasEdit}
          setHasEdit={setHasEdit}
        />
      </div>
      <div className=' w-full md:w-4/12 flex gap-2 items-center mb-3'>
          <div className="hidden md:block font-medium text-xs">Project </div>
          <Select
            style={{ width: '60%' }}
            options={projectList}
            value={projectList.find((i) => i.value == firstProject) || ""}
            disabled={projectList.length >= 2 ? false : true}
            // allowClear={formik.values[name]?true:false}
            // name={name}
            // placeholder={placeholder}
            onChange={handleProjectChange}
          />
        </div>
      <div className="w-full">
        <Gantt
          tasks={tasks}
          viewMode={view}
          onDateChange={hasEdit==true && handleTaskChange}
          onProgressChange={hasEdit==true &&  handleProgressChange}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? "155px" : ""}
          columnWidth={columnWidth}
          barBackgroundColor="#D0D0D0"
          barProgressSelectedColor={'#87d068'}
          barBackgroundSelectedColor={"#B8B8B8"}
          rowHeight={40}
          fontSize={12}
          barCornerRadius={7}
          barFill={60}
        />
      </div>
    </div>
  );
}

export default TaskSchedule;
