export function initTasks() {
    const tasks = [
      {
        start: new Date(),
        end: new Date(),
        name: "No Data",
        id: "p-1",
        progress:0,
        type: "project",
        hideChildren: false
      }
    ];
    return tasks;
  }
  export function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;
    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }
    return [start, end];
  }