import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import './App.css';
import Header from './Components/Layouts/Header';
import Sidebar from './Components/Layouts/Sidebar';
import Dasboard from './Components/Icons/Dasboard';
import { Suspense } from 'react';




function App() {
  const { Content } = Layout;
  return (
    <div className=' w-[100vw] h-screen'>
      <Layout className=' w-full h-screen'>
        <Sidebar />
        <Layout>
          <Header />
          <Content className=' bg-white'>
          <Suspense fallback={<div className=' w-full h-full flex justify-center items-center'>Loading...</div>}>
          <Outlet />
          </Suspense>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
