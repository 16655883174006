import React, { lazy } from 'react'
import { Route } from 'react-router-dom'


const MainMember =lazy(()=>import('../Pages/Members/main'))
const Members = lazy(()=>import('../Pages/Members/index'))
const CreateMember = lazy(()=>import('../Pages/Members/createMember'))
const Profile = lazy(()=>import('../Pages/Members/memberProfile'))
const EditMember = lazy(()=>import('../Pages/Members/editMember'))

function Memberroutes() {
  return (
    <Route path="members" element={<MainMember />}>
    <Route index element={<Members />} />
     <Route path="create" element={<CreateMember />} />
    <Route path="profile/:id" element={<Profile />} />
    <Route path="edit/:profileEditId" element={<EditMember />} />          
  </Route>
  )
}

export default Memberroutes