import React from "react";
import DataTable from "react-data-table-component";

const customStyles = {
  head: {
    style: {
      color: "white",
      fontSize: "12px",
      fontWeight: 500,
    },
  },
  headRow: {
    style: {
      backgroundColor: "#2A475E",
      minHeight: "35px !important",
    },
  },

  rows: {
    style: {
      color: "#67748E",
      fontSize: "12px",
      fontWeight: 400,
    },
  },
};

function DataTables({ column, datas,handlePerRowsChange,handlePageChange,totalRows,scrollHight }) {
  const columns = column;
  let NewData = datas;
  const data = NewData;
  return (
    <DataTable
      fixedHeader
      fixedHeaderScrollHeight={scrollHight?scrollHight:"80vh"}
      columns={columns}
      data={data}
      customStyles={customStyles}
      persistTableHead
      // pagination
      // paginationRowsPerPageOptions={[20,40,60,80]}
      // paginationServer
			// paginationTotalRows={totalRows}
			// onChangeRowsPerPage={handlePerRowsChange}
			// onChangePage={handlePageChange}
    />
  );
}

export default DataTables;