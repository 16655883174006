import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
const MainTaskPlan = lazy(()=>import('../Pages/TaskPlan/main')) 
const ViewTask = lazy(()=>import('../Pages/TaskPlan/viewTask'))  
const CreateTask = lazy(()=>import('../Pages/TaskPlan/createTask')) 
const EditTask = lazy(()=>import('../Pages/TaskPlan/editTask'))
const CreateSubTask = lazy(()=>import('../Pages/TaskPlan/createSubTask'))
const SubTaskView = lazy(()=>import('../Pages/TaskPlan/viewSubTask'))
const EditSubTask = lazy(()=>import('../Pages/TaskPlan/editSubTask'))
const TaskPlan = lazy(()=>import("../Pages/TaskPlan/MyTask")) 

function TaskplanRoutes() {
  return (
    <Route path="tasks-plan" element={<MainTaskPlan />}>
    <Route index element={<TaskPlan />} />
    <Route path="view/:taskViewId" element={<ViewTask />} />
    <Route path="create" element={<CreateTask />} />
    <Route path="edit/:taskEditId" element={<EditTask />} />
    <Route path="subtask/create" element={<CreateSubTask />} />
    <Route path="subtask/view/:subTaskViewId"element={<SubTaskView />}/>
    <Route
      path="subtask/edit/:subTaskEditId"
      element={<EditSubTask />}
    />
  </Route>
  )
}

export default TaskplanRoutes