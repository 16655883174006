import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

const User = lazy(()=>import('../Pages/User/index'));
const UserProfile  = lazy(()=>import('../Pages/User/userProfile'))
const EditProfile  = lazy(()=>import('../Pages/User/editProfile'))
const ChangePassword  = lazy(()=>import('../Pages/User/changePassword'))
const ProfileNotificationSettings = lazy(()=>import('../Pages/User/notication'))

function UserDetailsRoutes() {
  return (
    <Route path="user-details" element={<User />}>
    <Route index element={<UserProfile />} />
    <Route path="edit" element={<EditProfile />} />
    <Route path="password-change" element={<ChangePassword />} />
    <Route path="notifications" element={<ProfileNotificationSettings/>} />
  </Route>
  )
}

export default UserDetailsRoutes