import React, { lazy } from "react";
import { Route } from "react-router-dom";

const MainTeam = lazy(() => import("../Pages/Teams/teamMain"));
const Teams = lazy(() => import("../Pages/Teams"));
const AddMember = lazy(() => import("../Pages/Teams/AddMember"));
const EditMemberTeam = lazy(() => import("../Pages/Teams/EditMember"));
const TeamView = lazy(() => import("../Pages/Teams/TeamView"));

function TeamRoutes() {
  return (
    <Route path="teams" element={<MainTeam />}>
      <Route index element={<Teams />} />
      <Route path="add-members" element={<AddMember />} />
      <Route path="edit-members" element={<EditMemberTeam />} />
      <Route path="view" element={<TeamView />} />
    </Route>
  );
}

export default TeamRoutes;
