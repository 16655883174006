import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

const AccountsMain =lazy(()=>import('../Pages/Accounts/main')) 
const MainIncome =lazy(()=>import('../Pages/Accounts/Income/main')) 
const IncomeIndex =lazy(()=>import('../Pages/Accounts/Income')) 

function AccountsRoutes() {
  return (
    <Route path="accounts" element={<AccountsMain/>}>
    <Route path="income" element={<MainIncome/>}>
      <Route index element={<IncomeIndex/>} />
    </Route>
  </Route>
  )
}

export default AccountsRoutes