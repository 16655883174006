import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

const MainClient =lazy(()=>import('../Pages/Clients/clientMain')) 
const Client =lazy(()=>import('../Pages/Clients/index')) 
const CreateClients =lazy(()=>import('../Pages/Clients/createClient')) 
const EditClients =lazy(()=>import('../Pages/Clients/editClient')) 
const ClientView =lazy(()=>import('../Pages/Clients/clientView')) 

function ClientRoutes() {
  return (
    <Route path="clients" element={<MainClient />}>
    <Route index element={<Client />} />
    <Route path="create" element={<CreateClients />} />
    <Route path="edit/:clientEditId" element={<EditClients />} />
    <Route path="view/:clientViewId" element={<ClientView />} />
  </Route>
  )
}

export default ClientRoutes