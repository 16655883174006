import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

const MainProject  =lazy(()=>import("../Pages/Project/projectMain")) 
const AllProject =lazy(()=>import("../Pages/Project")) 
const CreateProject =lazy(()=>import("../Pages/Project/createProject"))
const EditProject =lazy(()=>import("../Pages/Project/editProject"))
const ViewProject =lazy(()=>import("../Pages/Project/ProjectView"))

const ProjectRoutes = () => {
  return (
    <Route path="projects" element={<MainProject />}>
    <Route index element={<AllProject />} />
    <Route path="create" element={<CreateProject />} />
    <Route path="edit/:projectEditId" element={<EditProject />} />
    <Route path="view/:projId" element={<ViewProject />} />
  </Route>
  )
}

export default ProjectRoutes