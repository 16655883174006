import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import App from "./App";
import Memberroutes from "./Routes/member.routes";
import PrivateRoute from "./Services/PrivateRouter";
import ProjectRoutes from "./Routes/project.routes";
import TeamRoutes from "./Routes/team.routes";
import TaskplanRoutes from "./Routes/taskplan.routes";
import TaskRoutes from "./Routes/task.routes";
import AuthRoutes from "./Routes/auth.routes";
import AccountsRoutes from "./Routes/accounts.routes";
import ReportsRoutes from "./Routes/reports.routes";
import Notifications from "./Pages/Mobile/Notifications/Notifications";
import UserDetailsRoutes from "./Routes/userDetails.routes";
import SettingsRoutes from "./Routes/settings.routes";
import SubConRoutes from "./Routes/subCon.routes";
import VendorRoutes from "./Routes/vendor.routes";
import NoteRoutes from "./Routes/nonte.routes";
import ClientRoutes from "./Routes/client.routes";
import TaskScheduleRoutes from "./Routes/taskSchedule.routes";
import MainChat from "./Pages/chat/chatMain";
import ChatIndex from "./Pages/chat";
import ChatProfile from "./Pages/chat/chatProfile";

const MainDashbord = lazy(() => import("./Pages/Dashboard/DashboardMain"));
const Dasahboard = lazy(() => import("./Pages/Dashboard"));
const SingleProject = lazy(() =>
  import("./Pages/Dashboard/Admin/SingleProject")
);

const Testroutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "") {
      window.location.replace("/dashboard");
    }
    if (location.pathname === "/auth") {
      window.location.replace("/auth/login");
    }
    if (location.pathname === "/accounts") {
      navigate("/accounts/income");
    }
  }, [location]);
  return (
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
                {/* <Suspense fallback={<div>Loading...</div>}> */}
                <App />
                {/* </Suspense> */}
            </PrivateRoute>
          }
        >
         <Route path="notifications" element={<Notifications/>} />
          <Route path="dashboard" element={
          <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
            <MainDashbord />
          </Suspense>}>
            <Route index element={
            <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
                <Dasahboard />
            </Suspense>
            } />
            <Route path=":proDashboardId" element={
                        <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
                        <SingleProject />
                    </Suspense>
            } />
          </Route>
          {TeamRoutes()}
          {Memberroutes()}
          {ProjectRoutes()}
          {TaskplanRoutes()}
          {ClientRoutes()}
          {NoteRoutes()}
          {TaskRoutes()}
          {ReportsRoutes()}
          {AccountsRoutes()}
          {UserDetailsRoutes()}
          {VendorRoutes()}
          {SubConRoutes()}
          {SettingsRoutes()}
          {TaskScheduleRoutes()}
          <Route path="chat" element={<MainChat/>}>
           <Route path="view" element={<ChatIndex />} >
           <Route path="chatProfile/:id" element={<ChatProfile />} />
          </Route>
        </Route>
        </Route>
        {AuthRoutes()}
      </Routes>
  );
};

export default Testroutes;
