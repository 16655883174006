import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

const MainVendor =lazy(()=>import('../Pages/Vendor/vendorMain')) 
const Vendor =lazy(()=>import('../Pages/Vendor/index')) 
const CreateVendor =lazy(()=>import('../Pages/Vendor/createVendor')) 
const EditVendor =lazy(()=>import('../Pages/Vendor/editVendor')) 
const VendorView =lazy(()=>import('../Pages/Vendor/vendorView')) 

function VendorRoutes() {
  return (
    <Route path="vendor" element={<MainVendor />}>
    <Route index element={<Vendor />} />
    <Route path="create" element={<CreateVendor />} />
    <Route path="edit/:vendorEditId" element={<EditVendor />} />
    <Route path="view/:vendorViewId" element={<VendorView />} />
  </Route>
  )
}

export default VendorRoutes