// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout} from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { NavItems } from "../Utils/NavItems";
import Logo from '../../assets/Icons/NuTaskLetter.png'

function Sidebar() {
  const { Sider } = Layout;
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      width={"10%"}
      style={{
        background: "#1B2838",
        overflow: "hidden",
        borderRight: "1px solid #E5E8ED",
      }}
    >
      <div className="h-[10%]  flex justify-center ">
        <div className=" w-16 h-16 flex justify-center  items-center  overflow-hidden mx-auto">
          {/* <span className=" font-extrabold text-lg text-[#212121]">MA</span> */}
          <img width="100%" src={Logo} alt="#" />
        </div>
      </div>
      <div className=" h-[90%] w-[80%]  mx-auto overflow-y-auto ">
        <div className="flex flex-col items-start  space-y-2 ">
        {NavItems.map((item, index) => {
          const { path, icon, title, access } = item;

          return (
            <>
              {access && (
                <NavLink to={path} 
                className={({ isActive }) =>
                isActive
                  ? `w-[100%] h-[30px] rounded-[9px] overflow-hidden bg-[#119DEE]/90 border shadow-md block`
                  : `w-[100%] h-[30px] overflow-hidden border block rounded-[9px]`
              }>
                  <div key={index} className=" flex gap-1 items-center">
                    <NavLink
                      to={path}
                    >
                      <span className=" w-[28px] h-[28px] border rounded-[9px] flex items-center justify-center ">
                        {/* <FontAwesomeIcon icon={icon} /> */}
                        {icon}
                      </span>
                    </NavLink>
                    <div className=" mt-1 text-xs font-medium text-white">
                      {title}
                    </div>
                  </div>
                </NavLink>
              )}
            </>
          );
        })}
        </div>
      </div>
    </Sider>
  );
}

export default Sidebar;
