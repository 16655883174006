import React, { lazy } from 'react'
import { Route } from 'react-router-dom'


const MainNotes =lazy(()=>import('../Pages/Notes/MainNotes')) 
const Notes =lazy(()=>import('../Pages/Notes')) 
const NoteCreate =lazy(()=>import('../Pages/Notes/CreateNote')) 
const NoteEdit =lazy(()=>import('../Pages/Notes/EditNote'))
const NotesView =lazy(()=>import('../Pages/Notes/ViewNotes'))

function NoteRoutes() {
  return (
    <Route path="mainNotes" element={<MainNotes />}>
    <Route index element={<Notes />} />
    <Route path="CreateNotes" element={<NoteCreate />} />
    <Route path="editNotes/:notesId" element={<NoteEdit />} />
    <Route path="viewNotes/:notesId" element={<NotesView />} />
  </Route>
  )
}

export default NoteRoutes