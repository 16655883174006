import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

const ReportsMain =lazy(()=>import('../Pages/Reports/Main')) 
const ReportIndex =lazy(()=>import('../Pages/Reports')) 
const ProjectReport =lazy(()=>import('../Pages/Reports/Project')) 
const TaskReport =lazy(()=>import('../Pages/Reports/Tasks')) 
const ClientsReport =lazy(()=>import('../Pages/Reports/Clients')) 
const MembersReport =lazy(()=>import('../Pages/Reports/Members')) 

function ReportsRoutes() {
  return (
    <Route path="reports" element={<ReportsMain/>}>
    <Route index element={<ReportIndex/>} />
    <Route path="projects" element={<ProjectReport/>}/>
    <Route path="tasks" element={<TaskReport/>}/>
    <Route path="clients" element={<ClientsReport/>}/>
    <Route path="members" element={<MembersReport/>}/>
  </Route>
  )
}

export default ReportsRoutes