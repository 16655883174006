import { Layout, Modal } from "antd";
import { Link, NavLink} from "react-router-dom";
import { useEffect, useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import logout from "../Utils/Logout";
import axiosInstance from "../../Services/axiosCommon";
import { BellOutlined } from "@ant-design/icons";
import useSwr from "swr";
import moment from "moment";
import NuDate from "../Utils/NuDate";
import { MenuOutlined } from "@ant-design/icons";
import { NavItems } from "../Utils/NavItems";

import loadable from '@loadable/component';

const Drawer = loadable(() => import('../Layouts/OtherAntd').then(comp=>comp.Drawer));
const Space = loadable(() => import('../Layouts/OtherAntd').then(comp=>comp.Space));
const Dropdown = loadable(() => import('../Layouts/OtherAntd').then(comp=>comp.Dropdown));
const Badge = loadable(() => import('../Layouts/OtherAntd').then(comp=>comp.Badge));




const { confirm } = Modal;

const Header = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const [noti, setNoti] = useState([]);
  const [count, setCount] = useState(0);
  const [title, setTitle] = useState({
    Name: "",
    Country: "",
  });

  const fetcher = (url) =>
    axiosInstance.get(url).then((res) => {
      res?.data?.data && setCount(res?.data?.data?.count);
      res?.data?.data?.notifications && setNoti(res?.data?.data?.notifications);
    });
  const { data, mutate } = useSwr(`/notification/user`, fetcher, {
    refreshInterval: 30000,
  });

  function getCompanyName() {
    axiosInstance
      .get(`CompanyInfo/1`)
      .then((res) => {
        res.data?.data &&
          setTitle({
            Name: res.data?.data?.companyName,
            Country: res.data.data?.companyLogo || "",
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCompanyName();
    // getNotifications();
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  function Menu() {
    const handleRead = async (id) => {
      axiosInstance
        .put(`/notification/update/is_read/${id}`, {
          is_read: 1,
        })
        .then((res) => {
          mutate();
          console.log(res.data);
        });
    };

    function ClearAll() {
      axiosInstance
        .delete(`/notification/delete`)
        .then((res) => {
          mutate();
        })
        .catch((err) => {});
    }

    function MarkAll() {
      axiosInstance
        .get(`/notification/update/mark_all_as_read`)
        .then((res) => {
          mutate();
        })
        .catch((err) => {});
    }

    return (
      <>
        <div className="w-72 h-96  bg-white shadow-lg overflow-hidden rounded-md">
          <div className=" w-[95%] py-2 px-2 bg-[#2A475E] flex justify-between items-center">
            <p className="font-medium text-white ">Notification</p>
            <div className=" flex items-center gap-2">
              {count > 0 ? (
                <button
                  className=" outline-none border-none text-[10px] font-normal px-2 py-1 rounded bg-[#119DEE] text-white cursor-pointer"
                  onClick={MarkAll}
                >
                  Mark all as read
                </button>
              ) : null}
              {noti.length > 0 ? (
                <button
                  className=" outline-none border-none text-[10px] font-normal px-2 py-1 rounded bg-[#119DEE] text-white cursor-pointer"
                  onClick={ClearAll}
                >
                  Clear all
                </button>
              ) : null}
            </div>
          </div>
          <div className="w-full max-h-[calc(24rem-40px)] overflow-y-auto  overflow-x-hidden">
            {noti?.map((notification) => {
              return (
                <>
                  <div
                    className={`${
                      notification?.is_read ? "bg-white" : " bg-slate-100"
                    } w-[95%] m-1 p-1 rounded flex justify-between items-start cursor-pointer`}
                    onClick={() => handleRead(notification?.notificationId)}
                  >
                    <div className="w-[15%]">
                      {/* <NuAvatarGroup userdetails={[{name:`${notification?.sender_fName}`,imageUrl:`${notification?.sender_imageUrl}`}]} />    */}
                      <div className=" w-8 h-8 bg-[#119DEE]  flex justify-center items-center text-white font-medium text-xs rounded-full overflow-hidden">
                        {notification.sender_imageUrl &&
                        notification.sender_imageUrl != "" &&
                        notification.sender_imageUrl != null ? (
                          <img
                            src={
                              notification.sender_imageUrl
                                ? notification.sender_imageUrl
                                : null
                            }
                            width="50px"
                            alt={`${notification.sender_imageUrl}`}
                          />
                        ) : (
                          `${notification?.sender_fName.charAt(0)}`
                        )}
                      </div>
                    </div>
                    <div
                      className={`w-[85%] ${
                        notification?.is_read
                          ? " font-normal "
                          : "font-medium  "
                      }`}
                    >
                      {/* <p className='font-semibold'>Admin <span className='font-normal'>to</span> Login Person</p> */}
                      <p className="pb-1 text-ellipsis leading-5 line-clamp-2 text-xs">
                        {notification?.msg}
                      </p>
                      <p className="text-[10px]">
                        {moment(notification?.date).format("DD-MM-YYYY")} at{" "}
                        <span className="text-[10px]">
                          <NuDate
                            value={`${notification?.date} ${notification?.time}`}
                            format="hh:mm a"
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* <p className='py-2 leading-5 cursor-pointer' onClick={()=> handleRead(notification?.notificationId
)}>{notification?.msg}</p>
            <div className='w-full h-[0.5px] bg-black'></div> */}
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  }

  const handleLogOut = async () => {
    confirm({
      title: `Do you want to Log out?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: ``,
      onOk() {
        logout();
      },
      onCancel() {},
    });
  };

  const { Header } = Layout;
  return (
    <Header
      style={{
        background: "rgba(102,192,244,0)",
        boxShadow: "",
        zIndex: "99",
        height: "50px",
      }}
      className=" shadow-sm flex justify-between items-center w-full"
    >
      <div className=" w-4 lg:w-6/12 h-full">
        <div className=" hidden h-full items-center lg:flex">
          {
            <div className=" flex justify-center items-center gap-2">
              <div className=" w-28 h-10 overflow-hidden flex justify-center p-1 items-center ">
                {title.Country && (
                  <img src={title.Country} width="100%" alt="#" />
                )}
              </div>
              <span className=" text-base capitalize font-semibold">
                {title.Name || " "}
              </span>
              {/* <small className=" text-[10px] ml-1 text-gray-500">{title.Country||" "}</small> */}
            </div>
          }
        </div>
        <div className=" lg:hidden cursor-pointer" onClick={showDrawer}>
          <MenuOutlined />
        </div>
      </div>
      <div className=" flex justify-center items-center">
        <div className="mr-6">
          <Space
            className={`${
              count <= 0
                ? " w-6 h-6 overflow-hidden bg-[#2A475E] flex items-center justify-center  rounded-xl"
                : ""
            }`}
          >
            <Badge
              count={count}
              color="rgb(45, 183, 245)"
              size="small"
              className={`bg-[#2A475E] ${
                count > 0 ? "p-[5px]" : ""
              }  rounded-xl`}
            >
              {screenSize.width <= "786" ? 
               <Link to={"/notifications"}>
                                              <BellOutlined
                               style={{ fontSize: "16px", color: "#fff" }}
                               className=""
                             />
               </Link>

                             :
                             <Dropdown overlay={<Menu />} trigger={["click"]}>
                             <BellOutlined
                               style={{ fontSize: "16px", color: "#fff" }}
                               className=""
                             />
                           </Dropdown>
              }
            </Badge>
          </Space>
        </div>

        <div class=" md:p-2 flex items-center">
          {screenSize.width <= "786" ? (
                              <Link
                              to={`/user-details`}

                            >
            <div className="mr-1 w-8 h-8 rounded-full bg-slate-300 overflow-hidden">
              {(userDetails?.image != null && (
                <img src={userDetails?.image} alt="#" width="110%" />
              )) || (
                <div className=" w-full h-full text-white flex justify-center items-center bg-[#2A475E]">
                  {userDetails?.userFirstName.charAt(0)}
                </div>
              )}
            </div>
            </Link>
          ) : (
            <div className="dropdown inline-block relative">
              <button className=" text-gray-700 outline-none border-none bg-transparent font-semibold md:px-4 flex items-center">
                <div className="mr-1 w-8 h-8 rounded-full bg-slate-300 overflow-hidden">
                  {(userDetails?.image != null && (
                    <img src={userDetails?.image} alt="#" width="110%" />
                  )) || (
                    <div className=" w-full h-full text-white flex justify-center items-center bg-[#2A475E]">
                      {userDetails?.userFirstName.charAt(0)}
                    </div>
                  )}
                </div>
                <div className=" hidden md:flex flex-col leading-none">
                  <p className=" text-xs font-normal">
                    {userDetails?.userFirstName || " "}{" "}
                    {/* {userDetails?.userLastName || " "} */}
                  </p>
                </div>
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
              </button>
              <ul className="dropdown-menu pt-2 leading-none z-50 absolute hidden shadow-sm">
                <li className="list-none">
                  <Link
                    to={`/user-details`}
                    className="rounded-t bg-gray-200 text-black hover:text-black hover:bg-gray-400 py-2 text-xs  font-normal px-4 block whitespace-no-wrap"
                    href="#"
                  >
                    Profile
                  </Link>
                </li>
                <li className="list-none">
                  <Link
                    to={`/user-details/notifications`}
                    className=" bg-gray-200 text-black hover:text-black hover:bg-gray-400 py-2 text-xs  font-normal px-4 block whitespace-no-wrap"
                    href="#"
                  >
                    Notifications
                  </Link>
                </li>
                <li className="list-none cursor-pointer">
                  <div
                    className="rounded-b bg-gray-200  hover:bg-gray-400 py-2 text-xs px-4 font-normal block whitespace-no-wrap"
                    href="#"
                    onClick={handleLogOut}
                  >
                    Log Out
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <Drawer title="" placement="left" onClose={onClose} open={open}>
        <div className=" h-full flex flex-col items-center space-y-1">
          {NavItems.map((item, index) => {
            const { path, icon, title, access } = item;
            return (
              <>
                {access && (
                  <NavLink
                    to={path}
                    className={({ isActive }) =>
                      isActive
                        ? `w-[100%] h-[28px] rounded-[6px] py-1 overflow-hidden border bg-sky-300 block text-white`
                        : `w-[100%] h-[28px] overflow-hidden py-1 border block rounded-[9px]`
                    }
                    onClick={onClose}
                  >
                    <div key={index} className=" flex  items-center">
                      <span className=" w-[28px] h-[28px] border rounded-[9px] flex items-center justify-center ">
                        {/* <FontAwesomeIcon icon={icon} /> */}
                      </span>
                      <div className=" mt-1 text-xs font-medium">{title}</div>
                    </div>
                  </NavLink>
                )}
              </>
            );
          })}
          <Link to="#" className="w-[100%] h-[28px] rounded-[6px] py-1 overflow-hidden border hover:bg-sky-300 block hover:text-white" onClick={()=>{handleLogOut();onClose()}}>
          <div className=" flex  items-center">
                      <span className=" w-[28px] h-[28px] border rounded-[9px] flex items-center justify-center ">
                        {/* <FontAwesomeIcon icon={icon} /> */}
                      </span>
                      <div className=" mt-1 text-xs font-medium">Logout</div>
                    </div>
          </Link>
        </div>
      </Drawer>
    </Header>
  );
};

export default Header;
