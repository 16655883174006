import React from 'react'
import { Route } from 'react-router-dom'
import MainTaskSchedule from '../Pages/TaskSchedule/main'
import TaskSchedule from '../Pages/TaskSchedule'

function TaskScheduleRoutes() {
  return (
    <Route path="tasks-schedule" element={<MainTaskSchedule />}>
      <Route index element={<TaskSchedule/>} />
    </Route>
  )
}

export default TaskScheduleRoutes