import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
const MainSubCon =lazy(()=>import('../Pages/SubCon/subConMain')) 
const SubCon =lazy(()=>import('../Pages/SubCon')) 
const CreateSubCon =lazy(()=>import('../Pages/SubCon/createSubCon')) 
const SubConView =lazy(()=>import('../Pages/SubCon/subConView')) 
const EditSubCon = lazy(()=>import('../Pages/SubCon/editSubCon'))

function SubConRoutes() {
  return (
    <Route path="sub-con" element={<MainSubCon />}>
    <Route index element={<SubCon />} />
    <Route path="create" element={<CreateSubCon />} />
    <Route path="edit/:subConEditId" element={<EditSubCon />} />
    <Route path="view/:subConViewId" element={<SubConView />} />
  </Route>
  )
}

export default SubConRoutes