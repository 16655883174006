import React, { lazy } from 'react'
import { Route } from 'react-router-dom'


const MainTask =lazy(()=>import("../Pages/Tasks/main"))
const ViewTask  =lazy(()=>import("../Pages/TaskPlan/viewTask"))
const SubTaskView  =lazy(()=>import("../Pages/TaskPlan/viewSubTask"))
const MyTaskTemp = lazy(()=>import("../Pages/Tasks/MyTask"))
const AllTaskTemp = lazy(()=>import("../Pages/Tasks/AllTasks"))

function TaskRoutes() {
  return (
    <Route path="tasks" element={<MainTask />}>
    <Route index element={<MyTaskTemp />} />
    <Route path="view/:taskViewId" element={<ViewTask />} />
    <Route path="all-task" element={<AllTaskTemp />} />
    <Route path="subtask/view/:subTaskViewId" element={<SubTaskView />}/>
  </Route>
  )
}

export default TaskRoutes